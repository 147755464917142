<template>
  <b-button
    :variant="isFavorite ? 'primary' : 'outline-primary'"
    size="sm"
    class="favorite-toggle accent"
    @click.prevent="toggle"
    pill
    :data-is-favorite="isFavorite"
    :disabled="posting"
  >
    <template v-if="showLabel">Favorite </template>
    <font-awesome-icon :icon="[isFavorite ? 'fas' : 'far', 'heart']" />
  </b-button>
</template>

<script>
export default {
  name: "VendorFavoriteToggle",
  props: {
    vendor: { type: Object },
    showLabel: { type: Boolean, default: true }
  },
  data() {
    return {
      posting: false
    };
  },
  computed: {
    isFavorite() {
      if (!this.$store.state.favorites) return false;
      return !!this.$store.state.favorites.find(i => i.key === this.vendor.key);
    }
  },
  methods: {
    toggle() {
      this.posting = true;
      const method = this.isFavorite ? "removeFavorite" : "addFavorite";
      this.$store
        .dispatch(method, this.vendor)
        .finally(() => (this.posting = false));
    }
  }
};
</script>

<style scoped lang="scss">
.btn {
  font-size: 0.7em;
  font-weight: 600;
  opacity: 0.9;
}

.btn[data-is-favorite] {
  opacity: 1;
}
</style>